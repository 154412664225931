a{
    text-decoration: none;
}
section{
    padding: 80px 18px 80px 80px;
}
/*.css-8w01ie-MuiDataGrid-root .MuiDataGrid-container--top [role=row]{*/
/*    background-color: #212121;*/
/*}*/
.css-rtrcn9-MuiTablePagination-root,
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
    color: inherit;
}
.MuiTablePagination-actions .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
    color: #ffffff4a;
}

/* Стиль для всей полосы прокрутки */
::-webkit-scrollbar {
    width: 12px; /* Ширина вертикального scrollbar */
    height: 12px; /* Высота горизонтального scrollbar */
}

/* Стиль для ползунка */
::-webkit-scrollbar-thumb {
    background-color: #212121;
    border-radius: 100px;
    border: 2px solid #ccc; /* Пространство вокруг ползунка */
}

/* Стиль для дорожки scrollbar */
::-webkit-scrollbar-track {
    background: #212121;
    border-radius: 100px;
}

/* Стиль для дорожки scrollbar при наведении */
::-webkit-scrollbar-thumb:hover {
    background: #505050;
}
/* Стиль для ползунка и дорожки scrollbar */
* {
    scrollbar-width: thin; /* толщина scrollbar */
    scrollbar-color: #505050 #212121; /* Цвет ползунка и дорожки */
}
.dark-input{
    padding-top: 10px!important;
}
.dark-input fieldset, .dark-input label, .dark-input input, .dark-input svg{
    border-color: #fff;
    color: #fff;
}
.dark-select ,.dark-select fieldset, .dark-select label, .dark-select input, .dark-select svg{
    border-color: #fff;
    color: #fff;
}
.dark-select .MuiSelect-select{
    color: #fff;
}

.css-v7h7tt-MuiDrawer-docked .MuiDrawer-paper{
    background: linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5));
    border: 1px solid #1A202D
}
.css-1he82yh-MuiDrawer-docked .MuiDrawer-paper{
    background: linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 1));
    border: 1px solid #1A202D
}

.MuiDialog-container .css-1x5jdmq,
.MuiDialog-container .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.MuiDialog-container .css-1uvydh2,
.MuiDialog-container .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.MuiDialog-container .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.MuiDialog-container .css-p0rm37 {
    color: #fff!important;
}
.MuiDialog-container .css-igs3ac, .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: #fff;
}
