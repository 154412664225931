.users {
  height: 579px;
  width: 100%;
}
.user_data{
  position: relative;
  &:after{
    content: "";
    width: 99%;
    background-color: rgba(255, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 46px;
    right: 0;
    margin: 0 auto;
    height: 99%;
  }
}